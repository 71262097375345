<template>
    <div v-if="!loader">
        <b-card class="row-height" no-body>
            <div>
                <b-card no-body class="card-statistics" style="">
                    <b-card-body class="statistics-body">
                        <h2 class="text-purple font-weight-bold mb-1">
                            <b-link class="btn btn-outline-primary btn-sm" to="/">
                                <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
                                {{ $t("back") }}
                            </b-link>
                            {{ $t("billing_information") }}
                        </h2>
                        <b-row>
                            <b-col cols="12" md="6" class="border shadow" v-if="$store.state.auth.user.crole == 'seller' && billing && billing.end_date">
                                <div class="d-flex  col-12 mb-1 mt-1">
                                    <span class="col-6"><strong>{{ $t("status") }}</strong></span>
                                    <span v-if="billing && billing.paid == 1" class="col-6 justify-content-end" align="end">
                    <b-button variant="outline-success" size="sm">    {{ $t("active") }} <FeatherIcon size="15" variant="success" icon="CheckIcon"></FeatherIcon></b-button>
                  </span>
                                </div>
                                <!-- <div class="d-flex col-12 mb-1">
                                    <span class="col-6"><strong>{{ $t("start_date") }}</strong></span>
                                    <span v-if="billing && billing.start" class="col-6 justify-content-end" align="end">{{billing.start | formatDate}}</span>
                                </div> -->
                                <div class="d-flex col-12 mb-1">
                                    <span class="col-6"><strong>{{ $t("subscription_valid_until") }}</strong></span>
                                    <span v-if="billing && billing.end_date" class="col-6 justify-content-end" align="end">{{billing.end_date | formatDate}}</span>
                                </div>
                                <div class="d-flex col-12">
                  <span class="col-6" v-if="preregistered">
                    <strong> {{ $t("preregistered") }}</strong>
                  </span>
                                </div>
                            </b-col>
                            <b-col cols="12" md="6" class="border shadow" v-else-if="$store.state.auth.user.crole == 'seller' && billing && billing.end_date == null">
                                <div class="d-flex  col-12 mb-1 mt-1">
                                    <feather-icon size="21" icon="AlertTriangleIcon"/>
                                    <span class="ml-1">
                    {{ $t("14_days_trial") }}
                  </span>
                                </div>
                            </b-col>
                        </b-row>
                        <br><br>
                        <b-row class="col-12" v-if="$store.state.auth.user.crole == 'seller'">
                            <b-button :variant="monthly ? 'primary' : 'outline-primary'" class="mb-1" @click="monthly = true">
                                <feather-icon icon="user"></feather-icon>
                                {{ $t("monthly") }}
                            </b-button>
                            <b-button
                                :variant="!monthly ? 'primary' : 'outline-primary'"
                                @click="monthly = false"
                                class="ml-xl-1 ml-lg-1 ml-md-1 ml-sx-0 mb-1"
                            >
                                <feather-icon icon="briefcase"></feather-icon>
                                {{ $t("report") }}
                            </b-button>
                        </b-row>
                        <div v-if="monthly && $store.state.auth.user.crole == 'seller'">

                            <b-table hover :items="monthlyData" :fields="monthlyFields" responsive>
                                <template #head()="scope">
                                    <div class="text-nowrap">{{ $t(scope.label) }}</div>
                                </template>
                                <template #cell(total)="data">
                                    <span>{{data.value | formatAmount}}</span>
                                </template>
                                <template #cell(month)="data">
                <span class="badge badge-light-secondary">
                   {{ getMonthName(data.value) }} </span>
                                </template>

                                <template #cell(pdf)="data">
                <span v-if="data.item.pdf" class="">
                {{ data.value }}
                <b-button
                    variant="outline-success"
                    @click="downloadPdf(data.item)"
                    style="font-size:8px !important"
                    size="sm"
                >
                  <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
                </b-button></span>
                                </template>
                            </b-table>
                        </div>
                        <div v-else>
                            <b-form>
                                <b-row>
                                    <b-col md="5" sm="6">
                                        <b-form-group label="Start Date:">
                                            <b-form-datepicker v-model="startDate"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="5" sm="6">
                                        <b-form-group label="End Date:">
                                            <b-form-datepicker v-model="endDate"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="2" sm="4" class="d-flex">
                                        <b-form-group label="" class="mt-md-2 mr-sm-1  pt-md-1">
                                            <b-button @click="getReport(afterSearch=true)" variant="primary" size="sm">
                                                Search
                                            </b-button>
                                        </b-form-group>
                                        <b-form-group label="" class="mt-md-2 pt-md-1 ">
                                            <b-button @click="downloadPdfReport()" variant="primary" size="sm" class="d-flex">
                                                <FeatherIcon size="12" icon="DownloadIcon"></FeatherIcon>
                                                PDF
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <b-table hover :items="reportData" :fields="reportFields" responsive>
                                <template #head()="scope">
                                    <div class="text-nowrap">{{ $t(scope.label) }}</div>
                                </template>
                                <template #cell(investor_id)="data">
                <span class="badge badge-secondary">
                   {{ data.value }} </span>
                                </template>
                                <template #cell(total)="data">
                                    <span>{{data.value | formatAmount}}</span>
                                </template>
                                <template #cell(invested_amount_per_invoice)="data">
                                    <p style="width: 120px !important;" class="mb-0 pb-0">{{data.value |
                                        formatAmount}}</p>
                                </template>
                                <template #cell(net_return_per_invoice)="data">
                                    <p style="width: 120px !important;" class="mb-0 pb-0">{{data.value |
                                        formatAmount}}</p>
                                </template>

                                <template #cell(status)="data">
                                    <span v-if="data.value == 11" class="light-green">{{$t('settled')}}</span>
                                    <span v-else class="text-warning">{{$t('overdue')}} / {{$t('pending')}}</span>
                                    <!--<span>{{checkInvoiceStatus(data.item.status, data.item.aequitex_invoice_id)}}</span>-->
                                </template>
                                <template #cell(period)="data">
                  <span class="badge badge-light-secondary">
                     {{ data.value }}
                  </span>
                                </template>
                            </b-table>
                            <div v-if="totalSaldo" class="col-12 row alert alert-secondary p-1">
                                <strong>{{ $t("total_saldo") }}: </strong>
                                <p class="ml-auto">{{ totalSaldo | formatAmount }}</p>
                            </div>
                        </div>
                        <div>
                        </div>
                    </b-card-body>
                </b-card>
            </div>
        </b-card>
    </div>
    <div v-else>
        <img src="/new-loader.svg"/>
    </div>
</template>
<script>
  import {ValidationProvider, ValidationObserver,} from "vee-validate";
  import Table from "@/components/common/Table.vue";
  import fileDownload from "js-file-download";

  import i18n from "@/libs/i18n";

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      Table
    },
    data() {
      return {
        totalSaldo: null,
        monthly: this.$store.state.auth.user.crole == 'seller' ? true : false,
        company: null,
        billing: null,
        startDate: null,
        endDate: null,
        loader: false,
        preregistered: false,
        monthlyData: null,
        monthlyFields: [
          {
            label: "month",
            align: "start",
            key: "month",
          },
          {
            label: "date",
            key: "date",
            sortable: false,
          },
          {
            label: "total",
            align: "start",
            key: "total",
            sortable: false,
          },
          {
            label: "pdf",
            align: "start",
            key: "pdf",
            sortable: false,
          },
        ],
        reportData: null,
        reportDataSeller: null,
        reportDataBuyer: null,
        reportFields: null,
        reportFieldsSeller: [
          {
            label: "account_holder",
            align: "start",
            key: "account_holder",
          },
          {
            label: "id",
            key: "seller_id",
            sortable: false,
          },
          {
            label: "account_type",
            align: "start",
            key: "account_type",
          },
          {
            label: "currency",
            align: "start",
            key: "currency",
            sortable: false,
          },
          {
            label: "period",
            align: "start",
            key: "period",
            sortable: false,
          },
          {
            label: "date_of_sale",
            align: "start",
            key: "date_of_sale",
            sortable: false,
          },
          {
            label: "aequitex_invoice_id",
            align: "start",
            key: "aequitex_invoice_id",
            sortable: false,
          },
          {
            label: "reference",
            align: "start",
            key: "referenz",
            sortable: false,
          },
          {
            label: "amount",
            align: "start",
            key: "original_invoice_amount_per_invoice",
            sortable: false,
          },
          {
            label: "discount",
            align: "start",
            key: "discount_amount_per_invoice",
            sortable: false,
          },
        ],
        reportFieldsBuyer: [
          {
            label: "account_holder",
            align: "start",
            key: "account_holder",
          },
          {
            label: "id",
            key: "investor_id",
            sortable: false,
          },
          {
            label: "account_type",
            align: "start",
            key: "account_type",
          },
          {
            label: "currency",
            align: "start",
            key: "currency",
            sortable: false,
          },
          {
            label: "period",
            align: "start",
            key: "period",
            sortable: false,
          },
          {
            label: "date_of_investment",
            align: "start",
            key: "date_of_investment",
            sortable: false,
          },
          {
            label: "date_of_disbursment",
            align: "start",
            key: "date_of_disbursement",
            sortable: false,
          },
          {
            label: "status",
            align: "start",
            key: "status",
            sortable: false,
          },
          {
            label: "aequitex_invoice_id",
            align: "start",
            key: "aequitex_invoice_id",
          }, {
            label: "reference",
            key: "referenz",
            sortable: false,
          },
          {
            label: "amount",
            align: "start",
            key: "invested_amount_per_invoice",
            sortable: false,
          },
          {
            label: "net_return",
            align: "start",
            key: "net_return_per_invoice",
            sortable: false,
          },
          {
            label: "discount",
            align: "start",
            key: "discount_per_invoice",
            sortable: false,
          },
        ],
      };
    },
    created() {
      this.getData();
      this.getBilling();
      this.getMonthlyData();
      this.getReport();
    },
    methods: {

      // addWorkingDays(dueDate, days) {
      //   // Реализуйте вашу логику добавления рабочих дней
      //   const date = new Date(dueDate);
      //   // console.log(date, dueDate);
      //   // throw new Error('asd')
      //   let addedDays = 0;
      //   while (addedDays < days) {
      //     date.setDate(date.getDate() + 1);
      //     if (date.getDay() !== 0 && date.getDay() !== 6) { // Пропускаем выходные
      //       addedDays++;
      //     }
      //   }
      //   return date;
      // },
      // checkInvoiceStatus(status, invoice_id) {
      //   const currentDate = new Date();
      //   const finalDate = this.addWorkingDays(invoice_id, 10);
      //
      //
      //   if (status === 11) {
      //     return 'Settled';
      //   } else if (finalDate < currentDate) {
      //     return 'Overdue';
      //   } else {
      //     return 'Pending';
      //   }
      // },

      downloadPdfReport() {
        let url = '';
        let payload = '';
        if (this.$store.state.auth.user.crole == 'seller') {
          url = '/report/seller/pdf';
          payload = {
            company_id: this.$store.state.auth.user.company_id,
            startDate: this.startDate,
            endDate: this.endDate
          }
        } else if (this.$store.state.auth.user.crole == 'buyer') {
          url = '/buyer/report/pdf';
          payload = {
            startDate: this.startDate,
            endDate: this.endDate
          }
        }
        this.$http
            .post(url, payload, {responseType: "blob",})
            .then((response) => {
              // let parsed = response.data.type.split("/");
              // let str = "doc." + parsed[1];
              fileDownload(response.data, 'report.pdf');
            })
            .catch((error) => {
            });
      },
      downloadPdf(item) {
        this.$http
            .post(
                "/company/invoiceForSeller/downloadInvoiceForSeller/" + item.id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              // let parsed = response.data.type.split("/");
              // let str = "doc." + parsed[1];
              fileDownload(response.data, 'monthly.pdf');
            })
            .catch((error) => {
            });
      },
      getMonthName(monthNumber) {
        const currentLanguage = this.$store.state.app.language;
        const months = {
          'en': [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
          ],
          'de': [
            "Januar", "Februar", "März", "April",
            "Mai", "Juni", "Juli", "August",
            "September", "Oktober", "November", "Dezember"
          ]
        };
        const index = Math.min(Math.max(parseInt(monthNumber, 10), 1), 12) - 1;
        return months[currentLanguage][index];
      },
      getData() {
        this.loader = true;
        this.$http
            .post(`/company/show/${this.$store.state.auth.user.company_id}`)
            .then((res) => {
              if (res) {
                this.company = res.data.value;
                if (res.data.value.buyer == 1) {
                  this.reportFields = this.reportFieldsBuyer;
                } else if (res.data.value.buyer == 0) {
                  this.reportFields = this.reportFieldsSeller;
                }
                this.loader = false;
              }
            });
      },
      getBilling() {
        this.$http
            .post(`/company/billing/get`, {company_id: this.$store.state.auth.user.company_id})
            .then((res) => {
              if (res) {
                this.billing = res.data.value;
                //this.startDate = res.data.value[0].start ? res.data.value[0].start.substring(10,0) : null;
                this.endDate = res.data.value && res.data.value.end_date ? res.data.value.end_date : null;
                this.getReport();
              }
            });
      },
      getMonthlyData() {
        if (this.$store.state.auth.user.crole == 'seller') {
          this.$http
              .post(`/company/invoiceForSeller/index`, {company_id: this.$store.state.auth.user.company_id})
              .then((res) => {
                if (res) {
                  this.monthlyData = res.data.value;
                }
              });
        } else if (this.$store.state.auth.user.crole == 'buyer') {
          this.$http
              .post(`/company/invoiceForSeller/index`, {company_id: this.$store.state.auth.user.company_id})
              .then((res) => {
                if (res) {
                  this.monthlyData = res.data.value;
                }
              });
        }
      },
      getReport(afterSearch) {
        if (this.$store.state.auth.user.crole == 'seller') {
          this.getReportDataSeller(afterSearch);
        } else if (this.$store.state.auth.user.crole == 'buyer') {
          this.getReportDataBuyer(afterSearch);
        }
      },
      getReportDataSeller(afterSearch = false) {
        let data = {
          company_id: this.$store.state.auth.user.company_id,
          startDate: this.startDate,
          endDate: this.endDate,
        }
        this.$http
            .post(`/report/seller`, data)
            .then((res) => {
              if (res && res.data.value && res.data.value.length > 0) {
                this.totalSaldo = res.data.value[0]?.total_saldo;
                this.reportData = res.data.value.slice(1);
              } else if (afterSearch) {
                this.$swal({
                  position: "center",
                  icon: "warning",
                  title: this.$t('no_invoices_sold_for_the_required_period'),
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            });
      },
      getReportDataBuyer(afterSearch) {
        let data = {
          startDate: this.startDate,
          endDate: this.endDate,
        }
        this.$http
            .post(`/buyer/report`, data)
            .then((res) => {
              if (res && res.data.value) {
                this.reportData = res.data.value.slice(1);
                this.totalSaldo = res.data.value[0].total_saldo;
              } else if (afterSearch) {
                this.$swal({
                  position: "center",
                  icon: "warning",
                  title: this.$t(res.data.message),
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            });
      },
      showSuccessMessage(message, type) {
        let text = this.$t(message);
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      },
    },
  };
</script>
